import { Link } from "react-router-dom";
import Input from "../../UiKit/Input/Input";
import SubmitButton from "../../UiKit/Buttons/SubmitButton/SubmitButton";
import stl from "./AuthForm.module.css";
import { createInternalUrlWithParams } from "../../../utils/common";
import useDirection from "@hooks/useDirection";

const AuthForm = ({
  fields,
  handleFieldsChange,
  onSubmit,
  postLoading,
  generalError,
  data,
  t,
  loading,
}) => {
  const dir = useDirection();
  return (
    <div className={stl.formWrapper}>
      <form onSubmit={onSubmit} className={stl.form}>
        <strong>{data?.banner?.title}</strong>
        <p className={`${stl.subTitle} ${generalError ? stl.hasError : ""}`}>
          {data?.banner?.description}
        </p>

        {generalError && <p className={stl.error}>{generalError}</p>}

        <Input
          type="number"
          name="phone_number"
          value={fields.phone_number.value}
          label={t("رقم الجوال", {
            ns: "web_auth",
          })}
          error={fields.phone_number.error}
          onChange={handleFieldsChange}
          className={stl.mb32}
          onlyNumbers
          maxLength={10}
          autoFocus
          placeholder={dir === "rtl" ? "00 000 000 05" : "05 000 000 00"}
        />

        <SubmitButton
          className={stl.btn}
          type="submit"
          loading={postLoading || loading}
          disabled={fields.phone_number.value.length < 10}
        >
          {t("ابدأ الآن", {
            ns: "web_auth",
          })}
        </SubmitButton>



        <p className={`${stl.font12} ${stl.grayText} ${stl.disclaimer}`}>
          {t("بمجرد انشاء حساب, توافق على", {
            ns: "web_auth",
          })}{" "}
          <a
            className={stl.link}
            href={createInternalUrlWithParams("/terms-landing")}
          >
            {t("الشروط والاحكام", {
              ns: "web_auth",
            })}
          </a>
          <span>
            {" "}
            {t("و", {
              ns: "web_auth",
            })}
          </span>{" "}
          <Link
            className={stl.link}
            to={createInternalUrlWithParams("/privacy-policy")}
          >
            {t("سياسة الخصوصية", {
              ns: "web_auth",
            })}
          </Link>
        </p>
      </form>
    </div>
  );
};

export default AuthForm;
