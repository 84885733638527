import { useEffect } from "react";
import Button from "../Buttons/SubmitButton/SubmitButton";

import stl from "./Otp.module.css";

const SendConfirmation = ({
  phoneNumber,
  onEdit,
  onSubmit,
  t,
  loading,
  className,
  content,
}) => {
  useEffect(() => {
    const handleSubmit = (e) => {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        onSubmit();
      }
    };

    document.addEventListener("keyup", handleSubmit);

    return () => document.removeEventListener("keyup", handleSubmit);
  }, []);

  return (
    <div className={`${stl.wrapper} ${className ? className : ""}`}>
      {content ? (
        <>
          {content}
          <Button
            type="button"
            className={`${stl.submit} ${stl.mt32}`}
            onClick={onSubmit}
            loading={loading}
          >
            {t("ارسل رمز التحقق", { ns: "web_common" })}
          </Button>
        </>
      ) : (
        <>
          <strong>
            {t("سنقوم بارسال رمز التحقق على:", { ns: "web_common" })}
          </strong>
          <strong>{phoneNumber}</strong>

          <Button type="button" className={stl.edit} onClick={onEdit}>
            {t("تعديل الرقم", { ns: "web_common" })}
          </Button>

          <Button
            type="button"
            className={`${stl.submit} ${stl.mt32}`}
            onClick={onSubmit}
            loading={loading}
          >
            {t("ارسل رمز التحقق", { ns: "web_common" })}
          </Button>
        </>
      )}
    </div>
  );
};

export default SendConfirmation;
