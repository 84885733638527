import { useLang } from "../../../../../../common/Contexts/LanguageContext";
import { CDN_LINK } from "@globalConfig";
import stl from "./DiscountLabel.module.css";

const DiscountLabel = ({ text }) => {
  const lang = useLang();
  return (
    <div className={`${stl.discountLabel}`}>
      <div className="interActiveBackOrange">
        <div>
          {lang == "ar" ? (
            <img
              width={"32px"}
              height={"16px"}
              src={CDN_LINK + "/assets/images/discount-ar.svg"}
              alt="discount"
              aria-label="discount"
            />
          ) : (
            <img
              src={CDN_LINK + "/assets/images/discount-en.svg"}
              alt="discount"
              aria-label="discount"
            />
          )}
        </div>
        <strong>{text}</strong>
        <span>ريال</span>
      </div>
    </div>
  );
};

export default DiscountLabel;
