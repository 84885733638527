import { useState} from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CDN_LINK } from "@globalConfig";
import { togglePostToFavorite } from "../../../../redux/actions/userActions";
import { createInternalUrlWithParams } from "../../../../utils/common";
import AuthPopUp from "../../../AuthComponents/AuthPopUp/AuthPopUp";
import stl from "./AddToFavorite.module.css";
import { trackEvents } from "../../../../common/events/events";
import ProgressiveImage from "@componentsShared/ProgressiveImage";

const AddToFavoriteButton = ({
  isFavorite: isFavorite__raw,
  postId,
  authCarCondition,
  className,
  page,
  g4_data,
}) => {
  const [isFavorite, setIsFavorite] = useState(isFavorite__raw);
  const loading = useSelector(({ auth }) => auth.loading);
  const navigate = useNavigate();

  const { loggedin } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const [showAuthModal, setShowAuthModal] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!loggedin && (page === "search" || page === "postView"))
      return navigate(
        createInternalUrlWithParams(
          `/user/signup/${page}/5/add_favorites/${postId}/1/${authCarCondition}?referralLocation=favorite`
        )
      );
    if (!loggedin) return setShowAuthModal(true);

    if (loading) return;
    dispatch(
      togglePostToFavorite(postId, 1, page, null, loggedin, (isFav) => {
        if (isFav === true) trackEvents("add_favorite", g4_data);
      })
    );
   setIsFavorite(!isFavorite);
  };

  return (
    <>
      <button
        id="add_favorite_button"
        className={[stl.btn, className].join(" ")}
        onClick={handleClick}
        aria-label="fav">
        {isFavorite ? (
            <ProgressiveImage
                waitUntilInteractive
            width={24}
            height={24}
            src={CDN_LINK + "/assets/images/favorite_red_1.svg"}
            alt="favorite car"
            aria-label="favorite car"
          />
        ) : (
          <ProgressiveImage
              waitUntilInteractive
          width={24}
          height={24}

          src={CDN_LINK + "/assets/images/favorite_border_blue_1.svg"}
          alt="non-favorite"
          aria-label="non-favorite"
        />
        )}
      </button>
        <AuthPopUp
            event="add_favorites"
            show={showAuthModal}
            onClose={(e) => {
                if (e) {
                    e.preventDefault();
                    e.stopPropagation();
                }
                setShowAuthModal(false);
            }}
            categoryId={5}
            carCondition={authCarCondition}
            popUpVerifyCb={() => {
                dispatch(
                    togglePostToFavorite(
                        postId,
                        1,
                        page,
                        null,
                        loggedin,
                        (isFav) => {
                            if (isFav === true) trackEvents("add_favorite", g4_data);
                        }
                    )
                );
                setShowAuthModal(false);
            }}
        />
    </>
  );
};

export default AddToFavoriteButton;
