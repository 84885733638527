import { useEffect, useState } from "react";
import { useTranslation } from "@i18n";
import classnames from "classnames";

import OtpInput from "react-otp-input";

import Countdown from "react-countdown";

import Button from "../Buttons/SubmitButton/SubmitButton";

import stl from "./Otp.module.css";


const VerifyOtp = ({
  phoneNumber,
  value,
  error,
  loading,
  subClassName,
  title,
  label,
  btnText,
  otpBtn,
  className,
  length,
  onEdit,
  onChange,
  onValidate,
  onResend,
  isOtpSent,
  setIsOtpSent,
  moreInformationSectionText,
  editNumberClass,
}) => {
  const { t } = useTranslation();

  const [showResend, setShowResend] = useState(true);
  const [showCountDown, setShowCountDown] = useState(false);
  const [showOtpSentMessage, setShowOtpSentMessage] = useState(false);
  const [isAutoTriggerd, setIsAutoTriggerd] = useState(false);

  useEffect(() => {
    if (!showOtpSentMessage) return;

    setTimeout(() => {
      setShowOtpSentMessage(false);
      setShowCountDown(true);
    }, 1000);
  }, [showOtpSentMessage]);


  useEffect(() => {

    isAutoTriggerd && onValidate()
    isAutoTriggerd && setIsAutoTriggerd(false)

  }, [isAutoTriggerd]);

  useEffect(() => {
    if (!isOtpSent) return;

    setShowOtpSentMessage(true);
    setShowResend(false);
  }, [isOtpSent]);






  useEffect(() => {
    // !isAutoTriggerd && value.length === length && setIsAutoTriggerd(true)
    const handleSubmit = (e) => {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        onValidate();
      }
    };

    document.addEventListener("keyup", handleSubmit);

    return () => document.removeEventListener("keyup", handleSubmit);
  }, [value]);


  return (
    <div
      className={`${stl.wrapper} ${error ? stl.hasError : ""} ${className ? className : ""
        }`}
    >

      <i className={stl.otpIcon}></i>
      {title && (
        <strong className={subClassName ? subClassName : ""}>
          {t(title, { ns: "web_common" })}
        </strong>
      )}
      <strong className={subClassName ? subClassName : ""}>
        {t("التحقق من رقم الهاتف", { ns: "web_common" })}
      </strong>


      <label>{label}</label>
      <strong
        className={[stl.number, subClassName ? subClassName : ""].join(" ")}
      >
        {phoneNumber}
      </strong>

      <Button
        type="button"
        className={`${stl.edit} ${editNumberClass ? editNumberClass : ""}`}
        onClick={onEdit}
      >
        {t("تعديل الرقم", { ns: "web_common" })}
      </Button>


      <OtpInput
        inputStyle={stl.optInput}
        inputType="number"
        containerStyle={stl.OtpWrapper}
        hasErrored={error}
        numInputs={length ? length : 4}
        onChange={onChange}
        renderSeparator={<></>}
        value={value}
        placeholder={new Array(length ? length : 4).fill("0").join("")}
        renderInput={(props) => <input {...props} type="tel" className={
          classnames({
            [stl.optInput]: true,
            [stl.otpError]: error,
          })
        } />}
        shouldAutoFocus
      />

      {error && <span className={stl.error}>{error}</span>}

      {showResend && (
        <p>
          {t("لم يصلني الرمز", { ns: "web_common" })}.
          <Button type="button" className={stl.submit} onClick={onResend}>
            {t("إعادة إرسال", { ns: "web_common" })}
          </Button>
        </p>
      )}
      {showOtpSentMessage && (
        <p className={stl.green}>
          {t("تم إعادة إرسال رمز التحقق.", { ns: "web_common" })}
        </p>
      )}

      {showCountDown && (
        <p className={stl.resend}>
          {t("يمكنك إعادة ارسال الرمز بعد", { ns: "web_common" })}
          <Countdown
            date={Date.now() + 59 * 1000}
            renderer={({ seconds }) => <strong>{seconds}</strong>}
            onComplete={() => {
              setShowCountDown(false);
              setShowResend(true);
              setIsOtpSent(false);
            }}
          />
          {t("ثانية.", { ns: "web_common" })}
        </p>
      )}

      {moreInformationSectionText && (
        <p className={stl.extraInfo}>{moreInformationSectionText}</p>
      )}

      <Button
        type="button"
        className={[stl.submit, otpBtn ? otpBtn : "", length !== value.length ? stl.notActive : undefined].join(" ")}
        onClick={onValidate}
        loading={loading}
      >
        {btnText}
      </Button>
    </div>
  );
};

export default VerifyOtp;
