import stl from "./CardHeader.module.css";
import DiscountLabel from "../../../NewComponents/Cards/SearchCard/DiscountLabel/DiscountLabel";
import MarkitingLabel from "../../../NewComponents/Cards/SearchCard/MarkitingLabel/MarkitingLabel";
import { isFavoritePost } from "@/utils/auth";
import AddToFavoriteButton from "../../../../UiKit/Buttons/AddToFavorite/AddToFavoriteButton";
import ProgressiveImage from "@componentsShared/ProgressiveImage";
import { thumbUrl } from "@/utils/imagesHelpers";
import { useTranslation } from "@i18n";
import useIsMobile from "@hooks/useIsMobile";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRef } from "react";
import {checkFeatureFlag} from "@/common/config/websiteConfig";
import useIsIOS from "@hooks/useIsIOS";
import {useLang} from "@/common/Contexts/LanguageContext";


const IOS_SWIPE_ENABLED = checkFeatureFlag('FF_ENABLE_MULTI_LIST_GALLERY_IOS_SWIPE')


const CardHeader = ({
  post,
  isEager,
  isCustomDesign,
  hideFavIcon,
  hideMarkitingLabel,
  hideDiscountLabel,
  TheImage = null,
  HasImageSlider = false,
  to_link,
  slideOnlyOnArr = false,
  isAnchor
}) => {
  const { t } = useTranslation(["web_search"]);
  const isMobile = useIsMobile();
  const isIos = useIsIOS();
  const lang = useLang();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showAllImages, setShowAllImages] = useState(true);
  const [openGalleryInPost, setOpenGalleryInPost] = useState(false);
  const [isSlideing, setIsSlideing] = useState(false);
  const Tag = isAnchor ? "a" : Link;
  const LinkType = isAnchor ? "href" : "to";

  const startX = useRef(0);
  const currentX = useRef(0);
  const isSwiping = useRef(false);


  const isSwipeEnabled = IOS_SWIPE_ENABLED && isIos || !isIos;


  useEffect(() => {
    if (selectedIndex !== 0) {
      setShowAllImages(true);
    }
  }, [selectedIndex]);

  const handleTouchStart = (event) => {
    if (!isSwipeEnabled) return;
    startX.current = event.touches[0].clientX;
    isSwiping.current = true;
  };

  const handleTouchMove = (event) => {
    if(!isSwipeEnabled) return;
    if (!isSwiping.current) return;
    currentX.current = event.touches[0].clientX;
  };

  const handleTouchEnd = () => {
      if(!isSwipeEnabled) return;
    if (!isSwiping.current) return;

    const deltaX = startX.current - currentX.current;
    if (lang === "ar") {
      if (deltaX < -50) {
        post.images_urls?.length - 1 !== selectedIndex && doSlide("next");
      } else if (deltaX > 50) {
        0 !== selectedIndex && doSlide("prev");
      }
    }
    if (lang === "en") {
      if (deltaX > -50) {
        post.images_urls?.length - 1 !== selectedIndex && doSlide("next");
      } else if (deltaX < 50) {
        0 !== selectedIndex && doSlide("prev");
      }
    }

    isSwiping.current = false;
  };

  const doSlide = (nextOrPrev) => {

    if (!isSlideing) {
      setIsSlideing(true);
      if (nextOrPrev === "prev") {
        selectedIndex === 0
          ? setSelectedIndex(post?.images_urls?.length - 1)
          : setSelectedIndex(selectedIndex - 1);
      }
      if (nextOrPrev === "next") {
        selectedIndex === post?.images_urls?.length - 1
          ? setSelectedIndex(0)
          : setSelectedIndex(selectedIndex + 1);
      }
      if (post.images_urls?.length === selectedIndex + 1) {
        setOpenGalleryInPost(true);
      }
    }
    setTimeout(() => {
      setIsSlideing(false);
    }, 30);
  };

  TheImage = TheImage
    ? TheImage
    : () => (
      <ProgressiveImage
        alt={post?.title ? post?.title : "car"}
        src={thumbUrl(post.image_url, "online-v1", "0x426")}
        loading={isEager ? "eager" : "lazy"}
        fetchpriority={isEager ? "high" : "low"}
        progressiveSmallImage={thumbUrl(
          post?.image_url,
          "online-v1",
          isMobile ? "0x99" : "0x154"
        )}
        width={205}
        height={154}
      />
    );

  return (
    <div className={stl.cardHead}>
      {post?.discount && !isCustomDesign && !hideDiscountLabel ? (
        <DiscountLabel text={post.discount} />
      ) : (
        ""
      )}
      {post.campaign_id &&
        !post.limited_quantity &&
        !isCustomDesign &&
        !hideMarkitingLabel ? (
        <MarkitingLabel
          campaign={{
            id: post.campaign_id,
            txt: post.campaign_txt,
            bg_color: post.campaign_bg_color_hex,
          }}
        />
      ) : (
        ""
      )}
      {post.is_booked ? (
        <div className={stl.reservedCarTag}>
          <span>{t("محجوزة", { ns: "web_search" })}</span>
        </div>
      ) : (
        ""
      )}
      {(post?.images_urls?.length === 1 || !HasImageSlider) &&
        post?.image_url?.length > 0 ? (
        <Tag   {...{ [LinkType]: to_link }}>
          <TheImage />
        </Tag>
      ) : (
        <div
          className={stl.carSliderCntainer}
          onTouchStart={!slideOnlyOnArr && handleTouchStart}
          onTouchMove={!slideOnlyOnArr && handleTouchMove}
          onTouchEnd={!slideOnlyOnArr && handleTouchEnd}
        >
          <span
            className={[
              stl.prevArr,
              selectedIndex === 0 ? stl.hideArr : undefined,
            ].join(" ")}
            onClick={() => {
              selectedIndex !== 0 && doSlide("prev");
            }}
          />

          <span
            className={[
              stl.nxtArr,
              selectedIndex === post.images_urls?.length - 1
                ? stl.hideArr
                : undefined,
            ].join(" ")}
            onClick={() => {
              selectedIndex !== post.images_urls?.length - 1 &&
                doSlide("next");
            }}
          />

          <span className={stl.counter}>
            {selectedIndex + 1}/{post?.images_urls?.length}
          </span>
          <Tag   {...{
            [LinkType]: to_link +
              (post.images_urls?.length === selectedIndex + 1
                ? "&bigGallView=true"
                : "")
          }}

          >
            {post.images_urls?.map((image, idx) => {

              return (
                (idx === 0 || showAllImages)
                && (

                  <ProgressiveImage
                    key={idx}
                    className={[
                      stl.singleItem,
                      selectedIndex === idx ? stl.activeItem : undefined,
                      idx === selectedIndex ? stl.activeSlide : undefined,
                      idx === selectedIndex ? "activeSlide" : undefined,
                      idx > selectedIndex ? stl.nextSlide : undefined,
                      idx < selectedIndex ? stl.prevSlide : undefined,
                      selectedIndex === post?.images_urls?.length - 1 && idx === 0
                        ? stl.actAsLst
                        : undefined,
                      idx === post?.images_urls?.length - 1 && selectedIndex === 0
                        ? stl.actAsfirst
                        : undefined,
                    ].join(" ")}
                    src={thumbUrl(
                      image,
                      "online-v1",
                      "0x426"
                    )}
                    alt={post?.title ? post?.title : "car"}
                    loading={idx === 0 && isEager ? "eager" : "lazy"}
                    fetchpriority={isEager ? "high" : "low"}
                    progressiveSmallImage={thumbUrl(
                      post?.image_url,
                      "online-v1",
                      isMobile ? "0x99" : "0x154"
                    )}
                    width={205}
                    height={154}
                  />
                )
              );
            })}
          </Tag>
          {post.images_urls?.length === selectedIndex + 1 && (
            <div className={stl.hoverdData}>
              <span>{t("شاهد جميع الصور", { ns: "web_search" })}</span>
            </div>
          )}
        </div>
      )}
      {!isCustomDesign && !hideFavIcon && (
        <AddToFavoriteButton
          isFavorite={isFavoritePost(+post.uniqueId)}
          postId={+post.uniqueId}
          authCarCondition={post.condition_id}
          className={stl.FavoriteButton}
          page="search"
          referral_location={"listing_page"}
          g4_data={post.g4_data_layer}
        />
      )}
    </div>
  );
};

export default CardHeader;
