import { useEffect, useState } from "react";
import {useTranslation} from "@i18n";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import OtpLimitExceeded from "../../UiKit/Otp/OtpLimitExceeded/OtpLimitExceeded";
import SendConfirmation from "../../UiKit/Otp/SendConfirmation";
import VerifyOtp from "../../UiKit/Otp/VerifyOtp";

import { sendOtp, verifyOtp } from "../../../redux/actions/commonActions";

const Otp = ({
  fullName,
  cityId,
  phoneNumber,
  otp,
  otpLength,
  scenario,
  loading,
  otpError,
  setOtpErrors,
  verifyOtpCb,
  onOtpChange,
  onEditNumber,
  className,
  SendConfirmationContent,
  showSendOtpConfirmation,
  setShowSendOtpConfirmation,
  title,
  label,
  subClassName,
  btnText,
  otpBtn,
  validateOtp,
  moreInformationSectionText,
  editNumberClass,
  verifyOtpAction,
   source = "login-or-signup"

}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpLimitExceeded, setIsOtpLimitExceeded] = useState(false);

  const sendOtpCb = (isResend) => {
    onOtpChange("");
    if (setShowSendOtpConfirmation) setShowSendOtpConfirmation(false);
    if (isResend) setIsOtpSent(true);
  };

  const handleSendOtp = (e, isResend) => {
    dispatch(
      sendOtp({
        phoneNumber: phoneNumber,
        cb: () => sendOtpCb(isResend),
        fcb: (errors) => {
          errors.forEach((error) => {
            if (error.name === "blocked"){
              setIsOtpLimitExceeded(true);
            }
            if (error.name === "otp") {
              setOtpErrors(error.message);
            }
          })
          
        },
        otpLength: otpLength,
        source:source
      })
    );
  };

  const handleVerifyOtp = () => {
    let msg;
    if (otpLength === 6) {
      msg = t("رمز التحقق يجب ان يتكون من 6 خانات", {
        ns: "web_common",
      });
    } else if (otpLength === 4) {
      msg = t("رمز التحقق يجب ان يتكون من 4 خانات", {
        ns: "web_common",
      });
    }
    const otpError = otp.includes("O") || otp.length === 0 ? msg : "";

    if (otpError) {
      setOtpErrors(otpError);
      return;
    }

    if (validateOtp) {
      const isValid = validateOtp();
      if (!isValid) return;
    }

    if (verifyOtpAction) {
      verifyOtpAction();

      return;
    }

    dispatch(
      verifyOtp({
        phoneNumber: phoneNumber,
        otp: otp,
        scenario: scenario,
        fullName: fullName,
        cityId: cityId,
        source: source,
        cb: (data) => verifyOtpCb(data),
        failureCb: (errors) => {
          errors.map((error) => {
            if (error.name=== "otp") {
              setOtpErrors(error.message);
            }
          });
        },
      })
    );
  };

  useEffect(() => {
    return () => {
      setIsOtpSent(false);
      setIsOtpLimitExceeded(false);
      if (setShowSendOtpConfirmation) setShowSendOtpConfirmation(true);
    };
  }, []);

  if (isOtpLimitExceeded)
    return (
      <OtpLimitExceeded
        onClick={() => navigate("/")}
        className={className ? className : ""}
      />
    );

  return (
    <>
      {showSendOtpConfirmation ? (
        <SendConfirmation
          content={SendConfirmationContent}
          phoneNumber={phoneNumber}
          onEdit={onEditNumber}
          onSubmit={handleSendOtp}
          loading={loading}
          className={className}
          t={t}
        />
      ) : (
        <VerifyOtp
          value={otp}
          phoneNumber={phoneNumber}
          loading={loading}
          subClassName={subClassName}
          title={title}
          label={
            label
              ? label
              : t("أدخل رمز التحقق للاستمرار", {
                  ns: "web_common",
                })
          }
          btnText={
            btnText
              ? btnText
              : t("تأكيد رقم الجوال", {
                  ns: "web_common",
                })
          }
          otpBtn={otpBtn}
          length={otpLength}
          className={className ? className : ""}
          error={otpError}
          isOtpSent={isOtpSent}
          onChange={onOtpChange}
          onEdit={onEditNumber}
          onValidate={handleVerifyOtp}
          onResend={(e) => handleSendOtp(e, true)}
          setIsOtpSent={setIsOtpSent}
          moreInformationSectionText={moreInformationSectionText}
          editNumberClass={editNumberClass}
        />
      )}
    </>
  );
};

export default Otp;
